<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-06 09:22:15
 * @LastEditTime: 2023-03-06 14:44:32
 * @Descripttion: 试卷审核
-->
<style lang="scss" scoped>
.examination-audit {
    @include innerPage(40px 34px 48px 15px);
    @include pageTitle(0);
    @include defalutTable(calc(100% - 46px), 30px);
    @include pageFoot;
}

.dialog-container {
    box-sizing: border-box;
    padding: 40px 58px 40px 14px;
}
</style>

<template>
    <section class="examination-audit">
        <div class="page-title">
            <h3>试卷审核</h3>
            <div class="search-form">
                <el-form inline :model="searchForm">
                    <el-form-item>
                        <el-select v-model="searchForm.scpap_examine" placeholder="选择审核状态" clearable @clear="searchData">
                            <el-option label="待审核" :value="10" />
                            <el-option label="已通过" :value="20" />
                            <el-option label="已驳回" :value="30" />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model.trim="searchForm.query_word" maxlength="100" placeholder="输入试卷关键字" clearable
                            @clear="searchData" />
                    </el-form-item>
                </el-form>
                <el-button type="custom_primary" size="medium" @click="searchData">查询</el-button>
            </div>
        </div>
        <div class="table-box">
            <div class="table-inner" ref="tableInner">
                <el-table :data="tableData" height="100%">
                    <el-table-column align="center" prop="scpap_title" label="试卷名称" min-width="28.15%" />
                    <el-table-column align="center" prop="teuse_name" label="共享人" min-width="10.81%" />
                    <el-table-column align="center" label="状态" min-width="16.6%">
                        <template slot-scope="scope">
                            <p class="table-status"
                                :class="{ failed: scope.row.scpap_examine == 30, success: scope.row.scpap_examine == 20, warning: scope.row.scpap_examine == 10 }">
                                {{ scope.row.scpap_examine == 30 && '已驳回' || scope.row.scpap_examine == 20 && '已通过' ||
                                    '待审核' }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="共享时间" min-width="21.72%">
                        <template slot-scope="scope">
                            {{ scope.row.create_time * 1000 | formatTime }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" min-width="22.74%">
                        <template slot-scope="scope">
                            <div class="operation-buttons size--2">
                                <el-button type="custom_primary" plain size="mini"
                                    @click="$router.push({ name: 'AUDIT_EXAMINATION_DETAIL', params: { audit: 1, id: scope.row.scpap_id } })">
                                    查看
                                </el-button>
                                <el-button type="custom_success" plain size="mini" v-if="scope.row.scpap_examine == 10"
                                    @click="showDialog(scope.row.scpap_id)">
                                    审核
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-foot custom-foot">
                <customPagination :current='tablePage.pageIndex' :total="tablePage.total" @pageChange="flippingPage" />
            </div>
        </div>
        <el-dialog title="审核" width="558px" :show-close="false" :visible.sync="auditDialog" @close="hideDialog">
            <div class="dialog-container">
                <el-form label-width="84px" ref="auditForm" :model="auditForm" :rules="auditRules">
                    <el-form-item label="审核：">
                        <el-radio-group v-model="auditForm.scpap_examine">
                            <el-radio :label="20">通过</el-radio>
                            <el-radio :label="30">驳回</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="审核意见：" prop="scpap_reason">
                        <el-input v-model.trim="auditForm.scpap_reason" type="textarea" maxlength="200"
                            placeholder="请输入审核意见" autosize />
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer">
                <el-button type="custom_info" size="small" @click="hideDialog">
                    取 消
                </el-button>
                <el-button type="custom_primary" size="small" :disabled="submitLoad" @click="submitAduitResult" v-loading="submitLoad">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import { $auditPaperList, $auditPaper } from "@api/audit"
import customPagination from "@comp/customPagination"
export default {
    name: 'audit_examinationAudit',
    components: { customPagination },
    data() {
        let validateReason = (rule, value, callback) => {
            if (this.auditForm.scpap_examine == 30 && !value) {
                callback(new Error("请填写审核意见"));
            } else {
                callback();
            }
        };
        return {
            searchForm: {},
            tableData: [], // 表格参数
            /* 表格分页参数 */
            tablePage: {
                pageIndex: 1,
                total: 0
            },
            paperId: null, // 资源id
            auditDialog: false, // 审核弹窗
            auditForm: {
                scpap_examine: 20
            }, // 审核表单
            auditRules: {
                scpap_reason: [
                    { required: true, validator: validateReason, trigger: "blur" },
                ],
            },
            submitLoad: false,
        }
    },
    created() {
        this.getData();
    },
    methods: {
        /** 获取试卷列表数据 */
        async getData() {
            let params = {
                ...this.searchForm,
                scpap_ischildshare: 10,
                pageindex: this.tablePage.pageIndex
            };
            let { data: res } = await $auditPaperList(params);
            this.tablePage.total = res.allcount;
            this.tableData = res.data;
            this.$forceUpdate();
        },
        /** 搜索 */
        searchData() {
            this.tableData = [];
            this.tablePage.pageIndex = 1;
            this.getData();
        },
        /** 翻页 */
        flippingPage(val) {
            this.tablePage.pageIndex = val;
            this.getData();
        },
        /** 提交审核结果 */
        submitAduitResult() {
            this.$refs.auditForm.validate(async (valid) => {
                if (valid) {
                    this.submitLoad = true;
                    let params = {
                        ...this.auditForm,
                        scpap_id: this.paperId
                    }
                    let res = await $auditPaper(params);
                    this.submitLoad = false;
                    if (res) {
                        this.hideDialog();
                        this.$message({
                            type: "success",
                            duration: 1500,
                            message: "审核结果提交成功",
                        });
                        this.getData();
                    }
                }
            });
        },
        /** 打开审核弹窗 */
        showDialog(id) {
            this.paperId = id;
            this.auditDialog = true;
        },
        /** 关闭审核弹窗 */
        hideDialog() {
            this.auditDialog = false;
            this.paperId = null;
            this.$refs.auditForm.resetFields();
            this.auditForm = { scpap_examine: 20 };
        }
    },
}
</script>